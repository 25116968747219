import React from "react";

// Images
import { VideoIMg } from "../../assets/Img";
import MainTitle from "../MainTitle/MainTitle";

const WhyAtlbha = () => {
	return (
		<div className='out-features p-main'>
			<div className='container '>
				<MainTitle text={"لماذا اطلبها ؟"} />
				<div className='all' data-aos='fade-up-right' data-aos-once='true'>
					<a
						href='http://www.youtube.com/channel/UCPypPqJuGFAGHz33RI7c5wg'
						target='blank'>
						<img
							width='100%'
							height='100%'
							src={VideoIMg}
							alt='لماذا اطلبها '
							loading='lazy'
							className=' rounded-3'
						/>
					</a>
				</div>
			</div>
		</div>
	);
};

export default WhyAtlbha;
