import React from "react";
import MainTitle from "../MainTitle/MainTitle";
import NotFoundData from "../NotFoundData/NotFoundData";
import SouqAtlbhaSwiper from "../SwiperBox/SouqAtlbhaProductsSwiper/SouqAtlbhaSwiper";
import ShowAllProductsButton from "../ShowAllProductsButton";

const HomePageSection = ({
	sectionTitle,
	sliderData,
	isLoading,
	navigateTo,
}) => {
	return (
		<div className='stores-info p-main'>
			<div className='container'>
				<MainTitle text={sectionTitle} />
				<div>
					{sliderData?.length > 0 ? (
						<SouqAtlbhaSwiper productSwiperData={sliderData} />
					) : (
						<NotFoundData />
					)}
				</div>

				{sliderData?.length >= 5 && (
					<ShowAllProductsButton
						isLoading={isLoading}
						navigateTo={navigateTo}
					/>
				)}
			</div>
		</div>
	);
};

export default HomePageSection;
