import React from "react";
import { DetailBlogBox } from "../components/index";

const BlogDetail = () => {
    return (
        <>
            <DetailBlogBox />
        </>
    );
};

export default BlogDetail;
