import React from "react";

import "./WhatWeOffer.css";
import {
	HomeServiceFive,
	HomeServiceFour,
	HomeServiceOne,
	HomeServiceSeven,
	HomeServiceSix,
	HomeServiceThree,
	HomeServiceTow,
} from "../../assets/Img";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const WhatWeOfferData = [
	{
		id: 1,
		icon: HomeServiceOne,
		alt: "الدورات التدريبية في الصين",
		title: "دورات ورحلات تجارية  إلى الصين",
		description:
			"دورات عن بعد ورحلات تجارية من داخل الصين لتبدأ رحلتك في التجارة حيث نصطحبك برفقة خبراء في مجال الأعمال لتصل إلى مصانع الجملة....",
	},

	{
		id: 2,
		alt: "⁠⁠⁠الكونتينر المشترك",
		icon: HomeServiceTow,
		title: "⁠⁠⁠الكونتينر المشترك",
		description: "تتيح منصة اطلبها خدمة الكونتينر المشترك للتجار....",
	},

	{
		id: 3,
		alt: "سوق الجملة منصة اطلبها",

		icon: HomeServiceThree,
		title: "⁠⁠سوق اطلبها للجملة",
		description:
			"يوفر سوق اطلبها عدة أصناف وانواع مختلفة من المنتجات عالية الجودة وبأسعار منافسة جداً لجميع التجار ....",
	},

	{
		id: 4,
		alt: "التسويق عبر المشاهير ",

		icon: HomeServiceFour,
		title: "⁠⁠التسويق عبر المشاهير",
		description:
			"عمدت منصة اطلبها عقد شراكة مع منصات المشاهير لتمكين عملائها من سهولة التواصل المباشر مع مجموعة كبيرة من المشاهير....",
	},
	{
		id: 5,
		alt: "الاستيراد والتخزين منصة اطلبها",
		icon: HomeServiceFive,
		title: "⁠⁠الاستيراد والتخزين",
		description:
			"تسعى منصة اطلبها التسهيل على عملائها استيراد المنتجات من مصادر جملة الجملة وتخزينها....",
	},

	{
		id: 6,
		alt: "صناعة البراند الخاص في منصة اطلبها",
		icon: HomeServiceSix,
		title: "⁠صناعة براند خاص⁠",
		description:
			"نساعدك في اختيار الاسم وتصميم الهوية البصرية للبراند الخاص فيك وفق اشتراطات الهيئة السعودية للملكية الفكرية.... ",
	},

	{
		id: 7,
		alt: "إدارةالمتجر الالكتروني",
		icon: HomeServiceSeven,
		title: "⁠⁠إدارةالمتجر",
		description:
			"تتيح منصة اطلبها لعملائها موظفين متخصصين في إدارة المتاجر....",
	},
];

const WhatWeOffer = () => {
	const navigate = useNavigate();

	const goUpWindow = () => {
		window.scroll(0, 0);
	};
	return (
		<section className='what-we-offer-section '>
			<section className='container'>
				<section className='what-we-offer-box-content row  align-items-center'>
					{/* text */}
					<div className='col-lg-12 services-head-section mb-4 text-center'>
						<h2>ماذا نقدم لك؟</h2>

						<p>منصة اطلبها تقدم لك مجموعة من الخدمات لنجاح مشروعك التجاري</p>

						{/*	<button
							onClick={() => {
								navigateToMerchantRegister();
							}}>
							انشئ متجرك الآن
						</button>*/}
					</div>

					{/*boxes*/}

					<div className='col-12 mb-3'>
						<div className='row justify-content-center gap-3 '>
							{WhatWeOfferData?.map((item) => (
								<div
									data-aos-once='true'
									data-aos='fade-up'
									onClick={() => {
										goUpWindow();

										localStorage.setItem(
											"WhatWeOfferInfo",
											JSON.stringify(item)
										);
										navigate(
											`/whatWeOffer/${encodeURIComponent(
												item.title.replace(/[^a-zA-Z0-9\u0621-\u064A]+/g, "-")
											)}`
										);
									}}
									className='what-we-offer-boxs text-center col-xl-4 col-lg-2  col-md-12  '
									key={item.id}>
									<div className='mb-2'>
										<div>
											<img
												className='what-we-offer-img '
												src={item?.icon}
												alt={item.alt}
											/>
										</div>
										<h3 className='offer-title'>{item?.title}</h3>
									</div>

									<p className='offer-description'>{item?.description}</p>
								</div>
							))}
						</div>
					</div>

					<div className='col-12'>
						<bdi
							className='mt-4 more-service-btn'
							onClick={() => {
								navigate("/services");
							}}>
							المزيد من الخدمات
							<HiOutlineArrowNarrowLeft />
						</bdi>
					</div>
				</section>
			</section>
		</section>
	);
};

export default WhatWeOffer;
